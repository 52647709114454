exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-ad-page-js": () => import("./../../../src/templates/ad-page.js" /* webpackChunkName: "component---src-templates-ad-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-dhl-page-js": () => import("./../../../src/templates/dhl-page.js" /* webpackChunkName: "component---src-templates-dhl-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-openinghours-page-js": () => import("./../../../src/templates/openinghours-page.js" /* webpackChunkName: "component---src-templates-openinghours-page-js" */),
  "component---src-templates-phonerepair-page-js": () => import("./../../../src/templates/phonerepair-page.js" /* webpackChunkName: "component---src-templates-phonerepair-page-js" */),
  "component---src-templates-postbox-page-js": () => import("./../../../src/templates/postbox-page.js" /* webpackChunkName: "component---src-templates-postbox-page-js" */),
  "component---src-templates-postnord-page-js": () => import("./../../../src/templates/postnord-page.js" /* webpackChunkName: "component---src-templates-postnord-page-js" */),
  "component---src-templates-printingservices-page-js": () => import("./../../../src/templates/printingservices-page.js" /* webpackChunkName: "component---src-templates-printingservices-page-js" */),
  "component---src-templates-schenker-page-js": () => import("./../../../src/templates/schenker-page.js" /* webpackChunkName: "component---src-templates-schenker-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

